import Cookies from 'js-cookie';
function reveal() {
  var reveals = document.querySelectorAll(".p_title div");
  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;
    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("on");
    } else {
      reveals[i].classList.remove("on");
    }
  }
  var cookieAlert = document.querySelector(".cookie-alert");
  if (!Cookies.get('acceptCookies')) {
    if (cookieAlert) {
      cookieAlert.classList.add("show");
    }
  }



}

window.addEventListener("scroll", reveal);

// To check the scroll position on page load
reveal();

/*
var menu =  document.querySelector('button.hamburger');
if(menu){
  menu.addEventListener('click', () =>{
    console.log('clique menu');  
    menu.classList.add("open");
    /*
    if(document.querySelector('.navigation-menu').classList.contains('expanded')){
      console.log('abriu');
    }else{
      console.log('fechou');
    }
    */
/*
});
 

}
*/

window.addEventListener('scroll', function () {
  var headerEl = document.querySelector('header');
  let scroll = this.scrollY;
  // var windowHeight = window.innerHeight;
  if (scroll > 254) {
    // document.querySelector('header').style.height = "50px"; 
    document.querySelector('header').classList.add("scroll_");
    return;
  } else {
    document.querySelector('header').classList.remove("scroll_");
  }
  if (scroll > 254) return;

  const defaultHeight = 121;

  let newHeight = defaultHeight - scroll / 6;
  if (newHeight < 60) newHeight = 60;
  headerEl.style.height = newHeight + 'px';

});

/*
function scrollToTop(scrollDuration) {
  var scrollStep = -window.scrollY / (scrollDuration / 15),
    scrollInterval = setInterval(function(){
    if ( window.scrollY != 0 ) {
        window.scrollBy( 0, scrollStep );
    }
    else clearInterval(scrollInterval); 
 },15);
} 
*/



// Smooth scrolling to any internal tags
//var elem_ = document.querySelectorAll('#menu a[href*=#]:not([href=#])');
//document.querySelectorAll("#menu a[href*=#']")[0].href;
var elem_ = document.querySelectorAll('#menu a');
for (let index = 0; index < elem_.length; index++) {

  elem_[index].addEventListener('click', () => {

    if (window.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
      window.location.hostname === this.hostname) {
      var target = document.querySelector(this.hash);
      target = target.length ? target : document.querySelector('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        document.querySelector('html,body').animate({
          scrollTop: target.offset().top - 10
        }, 500);

        return false;
      }
    }
  });


}
var cookieAlert = document.querySelector(".cookie-alert");
setTimeout(() => {

  if (window.innerWidth < 720) {
    document.querySelector('#item_mob').style.display = "block";
    document.querySelector('#item_desk').style.display = "none";
  } else {
    if (document.querySelector('#item_desk')) {
      document.querySelector('#item_desk').style.display = "block";
    }
    if (document.querySelector('#item_mob')) {
      document.querySelector('#item_mob').style.display = "none";
    }
  }



  if (!Cookies.get('acceptCookies')) {
    if (cookieAlert) {
      cookieAlert.classList.add("show");
    }
  }




}, 200)



