import logo from './img/Logo_AMA.png';
import logoFooter from './img/Logo_AMA_branco.png';
import banner from './img/SaoPaulo.jpg';
import bannerMob from './img/SaoPauloMob.png';
import url_politica from './politica-ambiental-ama-advogados.pdf';
import politica_cookies from './politica-cookies.pdf';
import politica_privacidade from './politica-privacidade.pdf';
//import carbon from './img/gestao_ambiental1_.png';
import iso from './img/gestao_ambiental2_.png';
import iqnet from './img/gestao_ambiental3_.png';
import onu from './img/onu.png';
import agro from './img/agro.png';
import ambiental from './img/ambiental.png';
import telecomunicacoes from './img/telecomunicacoes.png';
import urbanistico from './img/urbanistico.png';
/* */
import marcos from './img/marcos.png';
import renata from './img/renata.png';
import larissa from './img/larissa.png';
import linkedin from './img/Logo_Linkedin.png';
import linkedinW from './img/linkedin_white.png';
import sobre_img from './img/sobre_img.png';

/* */
import parceiro1 from './img/parceiros-1.png';
import parceiro2 from './img/parceiros-2.png';
import parceiro3 from './img/parceiros-3.png';
import parceiro4 from './img/parceiros-4.png';

import Carousel from 'react-bootstrap/Carousel';
import Cookies from 'js-cookie';

import { Col, Row, Container, Nav, Button } from "react-bootstrap";


import React, { useState } from "react";


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './assets/script/home.js';


const Header = (props) => {
  return (
    <header>

      <nav className="navbar navbar-default">
        <div className="container-fluid">
          <div className="navbar-header">
            <a className="navbar-brand" href="/#">
              <img src={props.brand} className="logo" alt="logo Antonietii Matthes Advogados" /></a>
          </div>
          <div id="menu">
            {props.navMenuMobile}
            {props.navMenu}
          </div>
        </div>
      </nav>


    </header>
  );
};


const CarouselBn = () => {

  return (

    <div className="">
      <div className='divBanner'>
        <div id="item_desk">
          <Carousel slide={false}>

            <Carousel.Item interval={1000}>
              <img
                className="d-block w-100"
                src={banner}
                alt=""
              />

            </Carousel.Item>
            <Carousel.Item interval={500}>
              <img
                className="d-block w-100"
                src={banner}
                alt=""
              />
            </Carousel.Item>



          </Carousel>
        </div>
        <div id="item_mob">
          <Carousel slide={false}>

            <Carousel.Item interval={1000}>
              <img
                className="d-block w-100"
                src={bannerMob}
                alt=""
              />

            </Carousel.Item>
            <Carousel.Item interval={500}>
              <img
                className="d-block w-100"
                src={bannerMob}
                alt=""
              />
            </Carousel.Item>



          </Carousel>
        </div>
        {/* <img src={tarja} className="tarja" />  */}
        <div className="img-tarja"></div>
      </div>
      <div className='strip'>
        <p>Competência técnica, transparência</p>
        <p>e interação fácil: nossos três pilares</p>
      </div>
    </div>
  );
}


const AboutUs = () => {
  return (
    <div className="about">
      <p id="about" className="p_title">Sobre nós <div className=""></div></p>

      <div className="p_article">

        <Row className="row_about">
          <Col xs={12} md={12} lg={5} >
            <p>Fundado em janeiro de 2016, a equipe do escritório boutique AMA Advogados atua, com exclusividade, nas áreas em <div><span></span>Direito</div><div className='tr_2'><span></span> Ambiental, Urbanístico, Agronegócio e Telecomunicações.</div> Com a missão de atender seus clientes com a <div><span></span>agilidade e foco no resultado</div>, todos os trabalhos são desenvolvidos por profissionais de nível sênior e bilingues, garantindo segurança, suporte e atenção para a geração de valor.</p>
          </Col>
          <Col>
            <Row>
              <Col xs={12} md={6} lg={4} >
                <p className='p2'>Nosso fundador, <div><span></span><span></span>Rafael Antonietti</div> Matthes, é o <div><span></span>advogado doutor e mestre</div> em Direito Ambiental. Passou por grandes escritórios de advocacia e aperfeiçoou seu conhecimento e atendimento, por meio de cursos nacionais e internacionais.
                </p>
                <Button variant="secondary" href="https://www.linkedin.com/company/rmatthesadv/"> <img src={linkedinW} alt="" /> </Button>
              </Col>
              <Col xs={12} md={6} lg={3} >
                <img className="aboutP" src={sobre_img} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>


      </div>
    </div>
  );
};


const Team = () => {
  return (
    <div className="team">
      <p id="team" className="p_title">Nossa Equipe <div className=""></div></p>
      <div className="p_article">

        <Row>
          <Col xs={12} lg={4} className="card_ad um">
            <div>
              <img className="d-block person" src={marcos} alt="" />
              <p className="name">Marcos Silva</p>
              <a href='https://www.linkedin.com/in/marcos-vinicius-silva-9423b519b'>
                <img className="linkedin" src={linkedin} alt="" />
              </a>
              <p className="desc">Advogado Sênior</p>
              <p className="desc">Trabalhista e Empresarial</p>
            </div>
          </Col>
          <Col xs={12} lg={4} className="card_ad dois">
            <div>
              <img className="d-block person" src={renata} alt="" />

              <p className="name">Renata El Debs</p>
              <a href='https://www.linkedin.com/in/renata-elias-el-debs-mattaraia-b5ba73197'>
                <img className="linkedin" src={linkedin} alt="" />
              </a>
              <p className="desc">Advogada Sênior</p>
              <p className="desc">Ambiental, Admin e Empresarial</p>
            </div>
          </Col>

          <Col xs={12} lg={4} className="card_ad tres">
            <div>
              <img className="d-block person" src={larissa} alt="" />

              <p className="name">Larissa Detoni</p>
              <a href='https://www.linkedin.com/in/larissa-detoni-77696b226'>
                <img className="linkedin" src={linkedin} alt="" />
              </a>
              <p className="desc">Advogada Sênior</p>
              <p className="desc">Civel, Contratual e Empresarial</p>
            </div>
          </Col>

        </Row>


      </div>
    </div>
  );
}

const Partners = () => {
  return (
    <div className="partners">
      <p id="about" className="p_title">Parceiros <div className=""></div></p>
      <div className="p_article">

        <Row>
          <Col xs={6} md={3} >
            <img src={parceiro1} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={parceiro2} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={parceiro3} alt="" />
          </Col>
          <Col xs={6} md={3}>
            <img src={parceiro4} alt="" />
          </Col>
        </Row>


      </div>
    </div>
  );
};

const OccupationArea = () => {
  return (
    <div className='occupation_area'>
      <p id="area" className="p_title">Áreas de atuação <div className=""></div></p>
      <Carousel slide={false}>
        <Carousel.Item>
          <Row className="justify-content-md-center">
            <Col>
              <img
                className="d-block"
                src={ambiental}
                alt=""
              />
            </Col>
            <Col>

              <p className="cap">Direito Ambiental</p>


            </Col>
          </Row>
        </Carousel.Item>

        <Carousel.Item>
          <Row className="justify-content-md-center">
            <Col>
              <img
                className="d-block"
                src={urbanistico}
                alt=""
              />
            </Col>
            <Col>

              <p className="cap">Direito Urbanistico</p>

            </Col>
          </Row>
        </Carousel.Item>

        <Carousel.Item>
          <Row className="justify-content-md-center">
            <Col>
              <img
                className="d-block"
                src={telecomunicacoes}
                alt=""
              />
            </Col>
            <Col>

              <p className="cap">Direito das Telecomunicações</p>

            </Col>
          </Row>
        </Carousel.Item>

        <Carousel.Item>
          <Row className="justify-content-md-center">
            <Col>
              <img
                className="d-block"
                src={agro}
                alt=""
              />
            </Col>
            <Col>

              <p className="cap">Direito do Agronegócio</p>

            </Col>
          </Row>
        </Carousel.Item>

      </Carousel>
      <p className="text" >Nosso escritório está preparado para atender qualquer demanda na área ambiental. Somos especializados desde <div className="tr_3"><span></span> o atendimento preventivo/estratégico, até o contencioso judicial e administrativo.</div></p>
      <p className="text">Implantação e coordenação de sistemas de gestão ambiental e de política ESG (Environmental, Social and Governance), elaboração de relatórios GRI e pareceres jurídicos sobre assuntos diversos, assessoria e acompanhamento de licenciamentos ambientais, oportunidades decorrentes de instrumentos econômicos (pagamento por serviços ambientais, tributação ambiental e mercado de carbono), elaboração de defesas e impugnações em face de autos de infração e o ajuizamento e acompanhamento de ações judiciais em todas as instâncias, são alguns dos nossos principais diferenciais.</p>


    </div>

  );
}

const EnvManagement = () => {
  return (
    <div className='env_management'>
      <p id="management" className="p_title">Sistema de Gestão Ambiental<div className=""></div></p>
      <div className="card_">
        <p>O AMA Advogados possui Sistema de Gestão Ambiental implementado, conforme norma <b>NBR ISO 14.001</b>. Esta norma internacional permite demonstrar o compromisso assumido com a <b>proteção do ambiente através da gestão dos riscos ambientais.</b></p>
        <p>Por meio de seu Sistema de Gestão Ambiental, a alta direção do escritório AMA Advogados se compromete a cumprir o seguinte escopo: “Prestar serviços de advocacia, consultiva e contenciosa, primando, continuamente, pelo controle, registro e redução dos aspectos e impactos adversos ao meio ambiente, que decorram dos serviços prestados no interior de sua sede e dos serviços prestados perante – ou com o apoio - das partes interessadas. Se compromete a atuar com vistas a difundir e a fomentar a regularização ambiental nos empreendimentos e atividades pertencentes à sua carteira de clientes”.</p>
        <p>Nós apoiamos e somos parceiros do <b>Pacto Global da ONU</b>, uma chamada para que as empresas alinhem suas estratégias e operações aos Dez Princípios e desenvolvam ações que contribuam para o enfrentamento dos desafios da sociedade. <b>É a maior iniciativa de sustentabilidade corporativa do mundo</b>, com mais de 16 mil membros.</p>
        <a className="" href={url_politica} target="_blank" rel="noreferrer" >Baixe aqui a política ambiental AMA Advogados</a>
      </div>
      <Row className="justify-content-md-center icons">
        <Col>
          <img className="icon" src={iso} alt="" />
        </Col>
        <Col>
          <img className="icon" src={iqnet} alt="" />
        </Col>
        <Col>
          <img className="icon" src={onu} alt="" />
        </Col>
       {/*  <Col>
          <img className="icon" src={carbon} alt="" />
        </Col> */}
      </Row>

    </div>
  );
};

const CustomersArea = () => {
  return (
    <div className='customers_area'>
      <p id="login" className="p_title">Área do cliente<div className=""></div></p>
      <div className="back_">
        <p>Acompanhe detalhes e informações importantes a qualquer hora e em qualquer lugar.</p>
        <Button href="https://rafaelmatthes.tecnojuris.com.br/">Efetuar Login</Button>
      </div>
    </div>
  );
};
const PopupArea = () => {
  const handleClick = (e) => {
    e.preventDefault();

    Cookies.set('acceptCookies', true, { path: '/', sameSite: 'strict' });
    document.querySelector(".cookie-alert").classList.remove("show");

  }
  return (
    <div className="card cookie-alert">
      <div className="card-body">
        <p className="card-text">Ao continuar navegando você concorda com as nossas
          <a target="_blank" rel="noreferrer" href={politica_cookies}> políticas de cookies</a> e
          <a target="_blank" rel="noreferrer" href={politica_privacidade}> políticas de privacidade</a>.
        </p>
        <div className="btn-toolbar justify-content-end">
          <Button
            onClick={handleClick}
            className="btn accept-cookies">Entendi</Button>
        </div>
      </div>
    </div>
  );
};

function ContainerBlock() {
  return (
    <Container>
      <AboutUs />
      <Team />
      <Partners />
      <OccupationArea />
      <EnvManagement />
      <CustomersArea />
    </Container>
  );
}
/*
const Overlay = () => {
  return <div className="overlay"> </div>;
};
*/
const Footer = (props) => {
  return (
    <div className="footer text-center text-lg-start bg-gray text-muted">

      <section id="contact" className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <Row className='w-100'>
          <Col xs={12} md={3}>
            <div className="me-5 d-lg-block">
              <img src={props.brand2} className="logo-footer" alt="" />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="end">
              <p>Av. Regente Feijó,944 - Sala 108 - Bloco B
                Jardim Anália Franco - CEP 03342-000 - São Paulo - SP
                Tel.: (11) 3280-6392
                contato@antoniettimatthes.com.br</p>
            </div>
          </Col>
          <Col></Col>
          <Col xs={12} md={5}>
            <div className="social">
              <a href="mailto:contato@antoniettimatthes.com.br" target='_blank' rel="noreferrer" className="me-3 text-reset google ">

              </a>
              <a href="https://wa.me/551132806392" className="me-3 text-reset whatsapp">

              </a>
              <a href="https://www.instagram.com/amaadvogados/" className="me-3 text-reset instagram">

              </a>
              <a href="https://www.linkedin.com/company/rmatthesadv/" className="me-3 text-reset linkedin">

              </a>
              <a href="https://www.facebook.com/profile.php?id=100089921910365" className="me-3 text-reset facebook">

              </a>


            </div>

          </Col>
        </Row>
      </section>

    </div>
  );
};

const MenuMobile = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false)

  return (
    <div className="menumobile">
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"} >
        <ul>
          <li>
            <a href="#about">Sobre nós</a>
          </li>
          <li>
            <a href="#team">Equipe e parceiros</a>
          </li>
          <li>
            <a href="#area">Áreas de atuação</a>
          </li>
          <li>
            <a href="#management">Gestão Ambiental</a>
          </li>
          <li>
            <a href="#login">Login</a>
          </li>
          <li>
            <a href="#contact">Contato</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

const Menu = () => {
  return (
    <Nav className="menu me-auto">
      <Nav.Link className="active" href="#about">Sobre nós</Nav.Link>
      <Nav.Link href="#team">Equipe e parceiros</Nav.Link>
      <Nav.Link href="#area">Áreas de atuação</Nav.Link>
      <Nav.Link href="#management">Gestão Ambiental </Nav.Link>
      <Nav.Link href="#login">Login</Nav.Link>
      <Nav.Link href="#contact">Contato</Nav.Link>
    </Nav>

  );
};
const App = () => {
  const [activeClass, setActiveClass] = React.useState(false);

  return (
    <div className="Home">
      <Header
        setActiveClass={setActiveClass}
        activeClass={activeClass}
        brand={logo}
        navMenu={<Menu />}
        navMenuMobile={< MenuMobile />}
      />

      <CarouselBn />

      <ContainerBlock />

      <PopupArea />

      <Footer

        brand2={logoFooter}
      />


    </div>
  );
};

//ReactDOM.render(<App />, document.getElementById("root"));


export default App;
